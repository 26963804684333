.form__textarea-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.form__label {
  color: #000000;
  font-family: "Poppins", sans-serif;
  padding: 5px;
}

.form__textarea {
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  width: 100%;
  height: 130px;
  resize: none;
}

.form__textarea:focus {
  outline: none;
}
