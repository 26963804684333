.signup {
  width: 100%;
}

.signup__container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
