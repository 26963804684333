.wrapper__container {
  display: flex;
  justify-content: center;
  align-self: center;
  height: 100vh;
}

.wrapper__title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.wrapper__title {
  color: #9370db;
  font-size: 14em;
  font-family: "Poppins", sans-serif;
}

.wrapper__subtitle {
  font-size: 3em;
  margin-bottom: 40px;
  font-family: "Poppins", sans-serif;
}

@media screen and (max-width: 578px) {
  .wrapper__title {
    font-size: 12em;
  }

  .wrapper__subtitle {
    white-space: wrap;
    text-align: center;
  }
}
