.lesson__content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.lesson__description {
  font-size: 20px;
  font-family: "Inter", sans-serif;
  max-width: 700px;
  white-space: pre-wrap;
}

.lesson__details-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
  margin-top: 60px;
}

.lesson__details-wrapper img {
  float: left;
  height: 30px;
  margin-right: 15px;
}

.lesson__button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}

@media screen and (max-width: 768px) {
  .lesson__button-wrapper {
    justify-content: center;
  }
}

@media screen and (max-width: 578px) {
  .lesson__details-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .lesson__description {
    text-align: center;
  }
}
