.form__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  min-height: 600px;
  width: 400px;
  border-radius: 40px;
  background-color: var(--quaternary-color);
}

.form__title {
  font-family: "Inter", sans-serif;
  text-align: center;
  color: #ffffff;
}

.form__signup-text {
  color: #ffffff;
}

.form__signup-link {
  color: #ffffff;
  text-decoration: underline;
  margin-left: 5px;
}

@media screen and (max-width: 448px) {
  .form__wrapper {
    width: 350px;
  }
}
