.form {
  width: 600px;
  max-height: 595px;
  background-color: var(--tertiary-color);
  border-radius: 10px;
  padding: 20px;
  z-index: 1;
}

.form__wrapper {
  display: flex;
  justify-content: space-around;
}

.avatar__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lab {
  background-color: var(--quaternary-color);
  border-radius: 30px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: "Inter", sans-serif;
  padding: 3px 15px;
  margin-top: 20px;
}

.lab__input {
  display: none;
}

@media screen and (max-width: 662px) {
}

@media screen and (max-width: 600px) {
  .form {
    display: inline-block;
    max-height: 800px;
    margin: 20px 50px 0 50px;
  }

  .form__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .avatar__wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
}
