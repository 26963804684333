.form__group {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.form__input {
  width: 300px;
  height: 50px;
  border: none;
  border-radius: 10px;
  padding: 10px;
  font-size: 18px;
}

.form__input:focus {
  outline: none;
}

.form__label {
  color: white;
  font-family: "Poppins", sans-serif;
  padding: 5px;
  inline-size: 300px;
  white-space: pre-line;
}
