.sidebar {
  position: fixed;
  height: 100%;
  width: 300px;
  background-color: #ffffff;
  padding: 50px 20px;
  left: 0;
  top: 0;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
  z-index: 10;
}

.sidebar__list {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.sidebar__item {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  color: black;
  transition: 0.3s;
}

.sidebar__item:hover {
  color: rgb(135, 135, 135);
}

.sidebar__icon-wrapper {
  font-size: 28px;
  margin-bottom: 30px;
}

.sidebar__icon {
  cursor: pointer;
}

.sidebar__hidden {
  transition: 0.5s ease-in-out;
  transform: translateX(-300px);
}
