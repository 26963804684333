.wrapper {
  min-height: 100vh;
  background-color: var(--secondary-color);
}

.lesson__container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  height: 75vh;
}

.lesson__title {
  font-family: "Inter", sans-serif;
}

.lesson__img {
  width: 100%;
  height: 350px;
  margin-top: 20px;
}

.lesson__button-wrapper {
  margin-left: auto;
}

@media screen and (max-width: 912px) {
  .wrapper {
    min-height: 130vh;
  }

  .lesson__container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
