.wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--primary-color);
}

.main {
  width: 100%;
  height: 100%;
}

.main__container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
}

.main__img {
  width: 100%;
  height: 400px;
  margin-bottom: 40px;
}

.main__buttons-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 75vh;
}

@media screen and (max-width: 846px) {
  .wrapper {
    height: 100%;
  }

  .main__container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}
