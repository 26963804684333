.wrapper {
  background-color: var(--primary-color);
}

.profile {
  position: relative;
  width: 100%;
  height: 655px;
}

.profile__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}

.profile__img-wrapper {
  display: flex;
  justify-content: center;
  z-index: 1;
  width: 30%;
}

.profile__img {
  width: 100%;
}

.profile__decor-img {
  position: absolute;
  left: -25px;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

@media screen and (max-width: 912px) {
  .profile__img-wrapper {
    display: none;
  }

  .profile__container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .profile__decor-img {
    position: absolute;
    left: 0;
    top: 0;
  }
}

@media screen and (max-width: 600px) {
  .profile {
    width: 100%;
    height: 120vh;
  }
}
