.form__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 600px;
  width: 400px;
  border-radius: 40px;
  background-color: var(--quaternary-color);
}

.form__title {
  font-family: "Inter", sans-serif;
  color: #ffffff;
}

@media screen and (max-width: 412px) {
  .form__wrapper {
    width: 350px;
  }
}
